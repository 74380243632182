// based on cellSize + borderSize from
// projects/crosswords/constants.js
$xword-cell-width: 31px;
$xword-cell-width-small: 24px; // only used in anagram helper preview cells
$xword-cell-width-xsmall: 20px; // as above but for long (10+ letter) clues
$xword-border-width: 1px;

$xword-highlight-colour: $highlight-main;
$xword-focussed-colour: #000000;
$xword-focussed-background-colour: #fff7b2;

$xword-clue-number-width: 32px;

$xword-grid-sizes: (
	 g-14: 14,
	 g-15: 15,
	 g-16: 16,
	 g-17: 17,
	 g-18: 18,
	 g-19: 19,
	 g-20: 20,
	 long: 20,
    cryptic: 15,
    prize: 15,
    quiptic: 15,
    genius: 15,
    speedy: 13,
    everyman: 15,
    weekend: 13
);
