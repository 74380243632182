$gauntlet-red: #b12938;
body {
  background-color: #fcfcfc !important;
}
/** Reset some basic elements */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

/** Basic styling */
body {
  font: 400 14px/1.5 "Amiri", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  color: #111;
  background-color: #fdfdfd;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/** Set `margin-bottom` to maintain vertical rhythm */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
.highlight {
  margin-bottom: 15px;
}

/** `main` element */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/** Images */
img {
  max-width: 100%;
  vertical-align: middle;
}

/** Figures */
figure > img {
  display: block;
}

figcaption {
  font-size: 14px;
}

/** Lists */
ul,
ol {
  margin-left: 30px;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

/** Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

/** Links */
a {
  color: #054a91;
  text-decoration: none;
}

.social-media-list a:hover {
  text-decoration: none;
}

.social-media-list a:hover .username {
  text-decoration: underline;
}

/** Blockquotes */
blockquote {
  color: #828282;
  border-left: 4px solid #e8e8e8;
  padding-left: 15px;
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;
}

blockquote > :last-child {
  margin-bottom: 0;
}

/** Code formatting */
pre,
code {
  font-size: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;
}

pre > code {
  border: 0;
  padding-right: 0;
  padding-left: 0;
}

/** Wrapper */

/** Clearfix */
.wrapper:after,
.footer-col-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

/** Icons */
.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #828282;
  padding-right: 5px;
  vertical-align: text-top;
}

.social-media-list li + li {
  padding-top: 5px;
}

/** Tables */
table {
  margin-bottom: 30px;
  width: 100%;
  text-align: left;
  color: #3f3f3f;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
}

table tr:nth-child(even) {
  background-color: #f7f7f7;
}

table th,
table td {
  padding: 10px 15px;
}

table th {
  background-color: #f0f0f0;
  border: 1px solid #dedede;
  border-bottom-color: #c9c9c9;
}

table td {
  border: 1px solid #e8e8e8;
}

/** Site header */
.site-header {
  border-top: 2px solid $gauntlet-red;
  border-bottom: 1px solid #e8e8e8;
  min-height: 55.95px;
  position: relative;
}

.site-title {
  font-size: 22px;
  font-weight: 300;
  line-height: 54px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
}

.site-nav {
  float: right;
  line-height: 54px;
}

.site-nav .nav-trigger {
  display: none;
}

.site-nav .menu-icon {
  display: none;
}

.site-nav .page-link {
  color: #111;
  line-height: 1.5;
}

.site-nav .page-link:not(:last-child) {
  margin-right: 20px;
}

@media screen and (max-width: 700px) {
  .site-nav {
    position: absolute;
    top: 9px;
    right: 28px;
    background-color: #fdfdfd;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    text-align: right;
  }
  .site-nav label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }
  .site-nav .menu-icon {
    display: block;
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;
  }
  .site-nav .menu-icon > svg {
    fill: #424242;
  }
  .site-nav input ~ .trigger {
    clear: both;
    display: none;
  }
  .site-nav input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }
  .site-nav .page-link {
    display: block;
    padding: 5px 10px;
    margin-left: 20px;
  }
  .site-nav .page-link:not(:last-child) {
    margin-right: 0;
  }
}

/** Site footer */
.site-footer {
  padding: 30px 0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: 15px;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: #828282;
  margin-left: -15px;
}

.footer-col {
  float: left;
  margin-bottom: 15px;
  padding-left: 15px;
}

.footer-col-1 {
  width: -webkit-calc(35% - (30px / 2));
  width: calc(35% - (30px / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (30px / 2));
  width: calc(20% - (30px / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (30px / 2));
  width: calc(45% - (30px / 2));
}

@media screen and (max-width: 800px) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (30px / 2));
    width: calc(50% - (30px / 2));
  }
  .footer-col-3 {
    width: -webkit-calc(100% - (30px / 2));
    width: calc(100% - (30px / 2));
  }
}

@media screen and (max-width: 600px) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (30px / 2));
    width: calc(100% - (30px / 2));
  }
}

/** Page content */
.page-content {
  padding-bottom: 30px;
  flex: 1;
}

.page-heading {
  font-size: 32px;
}

.post-list-heading {
  font-size: 28px;
}

.post-list {
  margin-left: 0;
  list-style: none;
}

.post-list > li {
  margin-bottom: 20px;
}

.post-meta {
  font-size: 14px;
  color: #828282;
}

.post-link {
  display: block;
  font-size: 22.4px;
}

/** Posts */
.post-header {
  margin-bottom: 30px;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;
}

@media screen and (max-width: 800px) {
  .post-title {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: 30px;
  font-size: 1.2em;
}

.post-content h2 {
  font-size: 32px;
}

@media screen and (max-width: 800px) {
  .post-content h2 {
    font-size: 28px;
  }
}

.post-content h3 {
  font-size: 26px;
}

@media screen and (max-width: 800px) {
  .post-content h3 {
    font-size: 22px;
  }
}

.post-content h4 {
  font-size: 20px;
}

@media screen and (max-width: 800px) {
  .post-content h4 {
    font-size: 18px;
  }
}

/** Syntax highlighting styles */
.highlight {
  background: #fff;
}

.highlighter-rouge .highlight {
  background: #eef;
}

.highlight .c {
  color: #998;
  font-style: italic;
}

.highlight .err {
  color: #a61717;
  background-color: #e3d2d2;
}

.highlight .k {
  font-weight: bold;
}

.highlight .o {
  font-weight: bold;
}

.highlight .cm {
  color: #998;
  font-style: italic;
}

.highlight .cp {
  color: #999;
  font-weight: bold;
}

.highlight .c1 {
  color: #998;
  font-style: italic;
}

.highlight .cs {
  color: #999;
  font-weight: bold;
  font-style: italic;
}

.highlight .gd {
  color: #000;
  background-color: #fdd;
}

.highlight .gd .x {
  color: #000;
  background-color: #faa;
}

.highlight .ge {
  font-style: italic;
}

.highlight .gr {
  color: #a00;
}

.highlight .gh {
  color: #999;
}

.highlight .gi {
  color: #000;
  background-color: #dfd;
}

.highlight .gi .x {
  color: #000;
  background-color: #afa;
}

.highlight .go {
  color: #888;
}

.highlight .gp {
  color: #555;
}

.highlight .gs {
  font-weight: bold;
}

.highlight .gu {
  color: #aaa;
}

.highlight .gt {
  color: #a00;
}

.highlight .kc {
  font-weight: bold;
}

.highlight .kd {
  font-weight: bold;
}

.highlight .kp {
  font-weight: bold;
}

.highlight .kr {
  font-weight: bold;
}

.highlight .kt {
  color: #458;
  font-weight: bold;
}

.highlight .m {
  color: #099;
}

.highlight .s {
  color: #d14;
}

.highlight .na {
  color: #008080;
}

.highlight .nb {
  color: #0086b3;
}

.highlight .nc {
  color: #458;
  font-weight: bold;
}

.highlight .no {
  color: #008080;
}

.highlight .ni {
  color: #800080;
}

.highlight .ne {
  color: #900;
  font-weight: bold;
}

.highlight .nf {
  color: #900;
  font-weight: bold;
}

.highlight .nn {
  color: #555;
}

.highlight .nt {
  color: #000080;
}

.highlight .nv {
  color: #008080;
}

.highlight .ow {
  font-weight: bold;
}

.highlight .w {
  color: #bbb;
}

.highlight .mf {
  color: #099;
}

.highlight .mh {
  color: #099;
}

.highlight .mi {
  color: #099;
}

.highlight .mo {
  color: #099;
}

.highlight .sb {
  color: #d14;
}

.highlight .sc {
  color: #d14;
}

.highlight .sd {
  color: #d14;
}

.highlight .s2 {
  color: #d14;
}

.highlight .se {
  color: #d14;
}

.highlight .sh {
  color: #d14;
}

.highlight .si {
  color: #d14;
}

.highlight .sx {
  color: #d14;
}

.highlight .sr {
  color: #009926;
}

.highlight .s1 {
  color: #d14;
}

.highlight .ss {
  color: #990073;
}

.highlight .bp {
  color: #999;
}

.highlight .vc {
  color: #008080;
}

.highlight .vg {
  color: #008080;
}

.highlight .vi {
  color: #008080;
}

.highlight .il {
  color: #099;
}

.post-list hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.post-content {
  float: none;
}

.featured-img-holder {
  display: inline-block;
  float: right;
  padding: 0 0 16px 16px;
}

.featured-img-holder img {
  display: block;
}

.featured-img-holder span {
  font-size: 0.6em;
  color: #888;
  text-align: right;
  display: block;
  padding-top: 2px;
}

.post-list li {
  padding: 16px 0;
  margin: 0 !important;
}

.post-list li img {
  float: right;
  max-width: 200px;
}

.post-list li h3 {
  margin-bottom: 4px;
}

.post-list li p {
  font-size: 1.1em;
  margin-bottom: 0;
}

.site-title {
  font-family: "Roboto Mono", "Amiri", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.post-title,
.post-link {
  font-family: "Vidaloka", "Amiri", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.footer-heading {
  font-size: 11px;
  color: #999;
}

.post-meta {
  font-size: 11px;
  font-family: cursive;
}

.site-nav .page-link {
  color: #666;
  font-size: 13px;
  font-family: "Roboto Mono", "Vidaloka", "Amiri", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.site-nav .page-link:hover {
  color: black;
  text-decoration: none;
}

.post-tags {
  font-size: 13px;
  color: #888;
}

.post-list hr:last-child {
  display: none;
}

.capitalize {
  text-transform: capitalize;
}

a.btn {
  display: inline-block;
  padding: 4px 8px;
  font-size: 0.6em;
  background: #485cb1;
  font-family: "Arial";
  border: 1px solid #41539f;
  color: white;
  border-radius: 2px;
  transition: background-color 0.2s ease;
}

a.btn:hover {
  background: #32407b;
  text-decoration: none;
}

a.post-link {
  color: #333;
}

/*# sourceMappingURL=main.css.map */

#root {
  overflow: hidden;
}

#footer {
  text-align: center;
  color: silver;
}

#App {
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

#submit-form-back {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;

  #submit-form {
    width: 40%;
    background: white;
    margin: 50px auto;
    border: 1px solid #888;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 1px rgba(0, 0, 0, 0.2);

    #sf-header {
      padding: 16px 0;
      text-indent: 16px;
      font-size: 1.5em;
      color: #444;
      border-bottom: 1px solid silver;
      background: #ddd;
    }

    #sf-inputs-holder {
      padding: 16px;
    }

    #sf-btn-holder {
      padding: 8px 0;

      button {
        display: inline-block;
        margin-right: 16px;
        border: 1px solid silver;
        padding: 4px 8px;
        cursor: pointer;
      }
    }

    label {
      display: block;
      padding-bottom: 6px;
      text-indent: 2px;
    }

    input {
      margin-bottom: 16px;
      display: block;
      width: 80%;
      outline: none;
      border: 1px solid silver;
      border-radius: 3px;
      padding: 8px 16px;
    }
  }
}

.crossword-thumb {
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 26px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: url("/images/chess.jpg") repeat;

  &:hover {
    a {
      background: rgba(255, 255, 255, 0.93);
      text-decoration: none;
      color: darken($gauntlet-red, 0%);
    }
    border-color: darken($gauntlet-red, 0%);
  }

  a {
    display: block;
    color: #444;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    padding: 30px 0;
    font-size: 1em;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.97);
    @media (max-width: 440px) {
      font-size: 0.9em;
    }
  }
}

#homepage-container {
  @media (min-width: 576px) {
    .gauntlet-header-row div {
      margin-bottom: -5px;
    }
  }
  @media (max-width: 576px) {
    .gauntlet-header-row div {
      margin-bottom: 20px;
    }
  }
}

#crossword-info-holder {
  padding-bottom: 8px;
  h1 {
    font-family: "Merienda", cursive;
  }

  #crossword-theme {
    padding-top: 8px;
  }

  h1,
  h2,
  h4,
  h6 {
    margin: 0;
  }

  h5 {
    margin-bottom: 0;
    display: inline-block;
  }

  #by {
    margin-bottom: 0px;
  }
}

.gauntlet-menu-row {
  h5 {
    margin: 0 auto;
  }

  a {
    color: #444;

    &:hover {
      text-decoration: none;
      color: black;
    }
  }

  .right-aligned {
    text-align: right;
  }
}

hr {
  margin-top: 6px !important;
  margin-bottom: 26px !important;
}

#logo-holder {
  text-align: center;
  img {
    width: 50px;
  }

  a {
    color: #444;
  }
}

#crossword-word {
  display: inline-block;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Spartan";
  position: relative;
  top: 13px;
  font-size: 3em;
}

a.site-title {
  font-size: 1.4em;
  font-family: "Spartan";
  color: $gauntlet-red;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

#crossword-container {
  @media (max-width: 768px) {
    text-align: center !important;
  }

  .gauntlet-header-row {
    display: block;
  }

  hr {
    margin-bottom: 15px !important;
    display: none;
  }

  @media (max-width: 767px) {
    #logo-holder {
      text-align: center;
    }
    #crossword-info-holder {
      text-align: center;
    }

    #crossword-published-date {
      margin-top: 10px;
    }
  }

  @media (min-width: 768px) {
    #logo-holder {
      text-align: left;
    }

    #crossword-published-date {
      margin-top: 30px;
    }
  }

  #menu-par {
    margin-top: 8px;
    margin-bottom: 0;

    a {
      font-size: 0.8em;
      display: inline-block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.crossword__controls {
  margin-top: 16px;
  button {
    font-size: 12px;
    margin-right: 8px;
    outline: 0;
    outline: none;

    &:focus,
    &:active {
      outline: 0 !important;
      outline: none;
    }
  }
}

.crossword__controls__grid {
  margin-top: 8px;
}

@mixin loading-spinner(
  $activeColor: #b12938,
  $selector: "&::before",
  $time: 0.5s
) {
  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  animation-play-state: running;
  opacity: 1;
  position: relative;

  &.-paused {
    animation-play-state: paused;
    opacity: 0.2;
    transition: opacity linear 0.1s;
  }

  #{$selector} {
    animation: $time linear infinite spinner;
    animation-play-state: inherit;
    border: solid 3px #dedede;
    border-bottom-color: #{$activeColor};
    border-radius: 50%;
    content: "";
    height: 40px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 40px;
    will-change: transform;
  }
}

.loading-spinner {
  @include loading-spinner;
  height: 100vh;
}

.cp {
  font-size: 10px;
  text-align: center;
  margin: 15px 0 25px 0;
}

#archived_by {
  margin: 0;
}

#compact-header {
  background: #fafafa;
  @media (max-width: 768px) {
    text-align: center !important;
  }

  border-bottom: 0.5px solid #e8e8e8;
  padding: 10px 0;

  a {
    color: #444;
  }

  .img-fluid {
    width: 20px;
  }

  h5 {
    display: inline-block;
    margin: 0;
    padding-left: 8px;
  }
}

.credits {
  font-size: 0.85em;
  margin-top: -2px;
  h5 {
  }
  @media (max-width: 768px) {
    text-align: center;
  }
  color: gray;

  span {
    font-weight: bold;
    color: #444;
  }
}

.site-nav {
  z-index: 1000;
}

.site-footer {
  padding: 8px 0;
}

$main-wrapper-width: 800;
$crossword-wrapper-width: 1000;

#main {
  padding-top: 30px;
}
.main-wrapper {
  max-width: -webkit-calc(800px - (30px * 2));
  max-width: calc(800px - (30px * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.crossword-wrapper {
  max-width: -webkit-calc(1200px - (30px * 2));
  max-width: calc(1200px - (30px * 2));
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 800px) {
  .wrapper {
    max-width: -webkit-calc(800px - (30px));
    max-width: calc(800px - (30px));
    padding-right: 15px;
    padding-left: 15px;
  }
}

input {
  outline: none;
}

@media screen and (max-width: 740px) {
  #crossword .crossword-wrapper {
    padding-left: 1px;
    padding-right: 1px;
  }

  .crossword__clue__text {
    text-align: left;
  }

  .crossword__controls,
  .crossword__clues--wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.crossword__container__game {
  padding-bottom: 0;
}
