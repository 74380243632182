@import '~sass-mq/_mq';
@import 'pasteup/src';

// Clamps a block of text to a certain number of lines,
// followed by an ellipsis in Webkit and Blink based browsers
// Reference: http://dropshado.ws/post/1015351370/webkit-line-clamp
@mixin text-clamp($lines: 2, $line-height: false) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;

    // Fallback for non-Webkit browsers
    // (won't show `…` at the end of the block)
    @if $line-height {
        max-height: $line-height * $lines;
    }
}

@mixin content-gutter {
    padding-left: $gs-gutter/2;
    padding-right: $gs-gutter/2;

    @include mq(mobileLandscape) {
        padding-left: $gs-gutter;
        padding-right: $gs-gutter;
    }
}
