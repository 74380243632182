.crossword__clues-header {
    @include fs-header(1);
    border-bottom: 1px dotted $brightness-86;
    padding-bottom: $gs-baseline * .75;
}

.crossword__clues-list, ol.crossword__clues-list {
    padding: 0;
    margin: 0;
    margin-bottom: $gs-baseline;
    list-style-type: none;
}

.crossword__clue {
    @include fs-textSans(4);
    text-decoration: none;
    position: relative;
    padding: $gs-baseline * .25 $gs-baseline $gs-baseline * .25 0;
    margin: $gs-baseline * .25 0;
    display: block;

    // Override link styles
    &:hover {
        text-decoration: none;
    }

    &,
    &:active {
        color: inherit;
    }
}

.crossword__clue__number,
.crossword__clue__text {
    display: table-cell;
}

.crossword__clue__number {
    font-weight: bold;
    -webkit-font-smoothing: initial;
    width: $gs-gutter;
    padding-right: $gs-gutter / 2;

    .has-grouped-clues & {
        width: $gs-gutter * 2;
    }
}

.crossword__clue--selected {
    background-color: $xword-focussed-background-colour;

    > * {
        font-weight: bold;
        color: $xword-focussed-colour;
    }
}

.crossword__clue--answered {
    color: $brightness-46;
}

//For grouped clues we display 11.12,23 accross instead of just a number
.crossword__clue--display-group-order:before {
    padding-right: 14px; // 14px - Matches the width between double digit numbers and clue of non-grouped clues
}
