$submit-btn-color:#4B8E4A;
$submit-btn-active-color:darken($submit-btn-color, 15%);
$clear-btn-color:#F2003C;
$clear-btn-active-color:darken($clear-btn-color, 15%);

.crossword__controls {
    display: block;
    margin-bottom: $gs-baseline / 2;

    .button {
        @include fs-textSans(4);
        margin-bottom: $gs-baseline;
    }


    .button--primary {
        background: $lifestyle-main;
        border: 1px solid $lifestyle-main;
        &:hover,
        &:visited,
        &:focus {
            background-color: darken($lifestyle-main, 10%);
            border-color: darken($lifestyle-main, 10%);
        }
    }

    .button--secondary {
		  cursor: pointer;
        color: $brightness-46;
        background: $brightness-93;
        border: 1px solid $brightness-93;
        &:hover,
        &:visited,
        &:focus {
            background-color: darken($brightness-93, 10%);
            border-color: darken($brightness-93, 10%);
        }

		  &.clear-btn {
			  	 color: white;
				 background: $clear-btn-color;
				 border-color: $clear-btn-active-color;

				 &:hover {
					 background: $clear-btn-active-color;
				 }
			 }


		  &.submit-btn {
			  	 color: white;
				 background: $submit-btn-color;
				 border-color: $submit-btn-active-color;

				 &:hover {
					 background: $submit-btn-active-color;
				 }
			 }
    }

    .button--secondary.crossword__controls__button--confirm {
        /* background-color: $brightness-7; */
        /* color: $brightness-100;
        border: 0; */
    }
}

.crossword__controls__clue .button:last-child {
    margin-right: 0;
}
