@import 'src';

.button {
    @include fs-textSans(2);
    @include button-height($base-size * 5);
    @include button-colour(
        $sport-bright,
        #ffffff,
        $sport-dark
    );
    display: inline-block;
    vertical-align: top;
    width: auto;
    font-weight: bold;
    text-decoration: none;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.button--primary {
    @include button-colour(
        $guardian-brand,
        #ffffff
    );

    @include button-hover-colour(
        darken($guardian-brand, 10%)
    );
}

.button--secondary {
    @include button-colour(
        $brightness-97,
        $brightness-7
    );

    @include button-hover-colour(
        darken($brightness-97, 10%)
    );
}

.button--tertiary {
    @include button-colour(
        transparent,
        $brightness-7,
        $brightness-86
    );

    @include button-hover-colour(
        transparent,
        darken($brightness-86, 10%)
    );
}

.button--large {
    @include button-height($base-size * 6);

    @include mq($from: desktop) {
        line-height: ($base-size * 6) + 2;
    }
}
